.header{
    /* height: 50px; */
    background: var(--bgcolor);
    border-bottom: none;
    /* position: fixed; */
    width: 100%;
    z-index: 1500;
}

.header .logoimg{
    height: 70px;
    width: 85px;
}
.header .navlist{
    gap: 30px;
}
.header .navlist a{
    color: var(--whitetext);
    text-decoration: none;
    font-weight: 700;
}
.header .navlist a:hover{
    color: var(--themecolor);
   
}
.header [class="navbar-toggler-icon"]{
    filter: invert(1);
    background-image: url('../images/menu.png') !important;
}
.offcanvas{
    background: #121217;
    z-index: 1600;
}
 .rightside .connectwalletbtn {
    background: var(--themegrad);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
}

/* .header .rightside .connectwalletbtn:hover {
    border: 1px solid var(--themecolor);
    background: none;
    color: var(--themecolor);
} */
.header .rightside .connectwalletbtn:hover {
    background: var(--themegrad1) !important;
    border: 1px solid transparent!important;
    color: rgb(255, 255, 255) !important;
    transition: none 0s ease 0s !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
 .networksec .netimg {
    width: 20px;
    height: 20px;
}

 .networksec .networkbtn {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    color: var(--whitetext);
    max-width: 185px;
    background: var(--cardbg);
    border: none;
    height: 32px;
    border-radius: 11px;
    justify-content: center;
    min-width: 120px;
}

 .networksec .networkbtn:hover {
    background: #5eabf926;
}

 .rightside {
    display: flex;
    gap: 10px;
    align-items: center;
}

 .rightside .settingicon {
    font-size: 25px;
}
.header .dropdown ,.offcanvas .dropdown
{
    position: relative;
    display: inline-block;

    padding: 10px;
}

.header .dropcontent,.offcanvas .dropcontent {
    display: none;
    position: absolute;
    background-color: var(--cardbg);
    min-width: 160px;
    z-index: 1;
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    /* bottom: 0px; */
    top: 42px;
    left: 0px;
    border-radius: 11px;
    z-index: 3;
    box-shadow: 0px 2px 2px #56525247;
}

.header .dropdown:hover {
    /* background-color: var(--cardbg); */
    border-radius: 10px;
}

.mobileheader .dropcontent {
    bottom: 61px;
    top: unset;
}

.mobileheader .dropcontent a {
    font-size: 14px;
}

.header .dropdown:hover .dropcontent ,.offcanvas .dropdown:hover .dropcontent {
    display: block;
}

.header .dropcontent a ,.offcanvas .dropcontent a {
    color: var(--whitetext);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.header .dropdown:hover .dropbtn .offcanvas .dropdown:hover .dropbtn  {
    color: var(--themecolor);
}

.header .dropcontent a:hover ,.offcanvas .dropcontent a:hover {
    color: var(--themecolor);
}
.header  a:hover ,.offcanvas  a:hover  {
    color: var(--themecolor);
}
.header .dropbtnactive,.offcanvas .dropbtnactive{
    color: var(--themecolor);
}
.header .dropbtnactive svg,.offcanvas .dropbtnactive svg{
    margin-top: -3px;
}

.header .dropdown:hover .dropcontent,.offcanvas .dropdown:hover .dropcontent  {
    display: block;
}

.header .dropbtn  {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--whitetext);
    background: none !important;
    border: none;
    width: 100%;

}
.offcanvas .dropbtn {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: start;
    color: var(--whitetext);
    background: none !important;
    border: none;
    width: 100%;

}
@media screen and (max-width:576px) {
     .rightside .connectwalletbtn {
        font-size: 12px;
    }
 
}
@media screen and (min-width:577px) and (max-width:767px) {
 .rightside .connectwalletbtn {
    font-size: 13px;
}
}
@media screen and (max-width:991px) {
     .rightside .networksec .networkbtn {
        min-width: 30px;
        max-width: 30px;
    }
     .rightside .networksec .networkbtn {
        min-width: 30px;
        max-width: 30px;
    }
  
    
}

@media screen and (max-width: 1199px) {
    .offcanvas .navlist a {
        color: var(--whitetext);
        text-decoration: none;
        font-weight: 700;
        margin-bottom: 20px;
    }  
    .dropdown{
        margin-bottom: 20px;
        padding: 0px !important;
        margin-left: -5px;
    }
    
}

.offcanvas [class="btn-close"]{
    filter: invert(1);

}
.toggler{
    border: none !important;
}