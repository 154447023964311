.innerpages {
    padding-top: 50px;
    padding-bottom: 70px;
    min-height: 70vh;
}

.swappage .swapcardrow {
    align-items: center;
}

.swappage .swapcardrow .cardstyle {
    background: var(--cardbg);
    padding: 20px;
    /* width: 450px; */
    clip-path: polygon(30px 0, calc(100% - 21px) 0, 100% 16px, 100% calc(100% - 11px), calc(100% - 16px) 100%, 10px 100%, 0 calc(100% - 11px), 0 25px);
}

.swappage .cardstyle .toprow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.swappage .cardstyle .toprow .headings {
    color: var(--whitetext);
    font-size: 18px;
    margin-bottom: 5px;
}

.swappage .cardstyle .toprow .desc {
    color: var(--themecolor);
    font-size: 14px;
    margin-bottom: 5px;
}

.swappage .cardstyle .toprow .iconsec {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

}

.swappage .cardstyle .toprow .iconsec svg {
    /* fill: var(--greytext); */
    fill:#6e7e99;
    font-size: 24px;
    cursor: pointer;
}

.swappage .cardstyle .toprow .iconsec svg:hover {
    fill: var(--themecolor);
}

.swappage .cardstyle .inputsec {
    background: var(--bgcolor);
    padding: 12px;
    border-radius: 12px;
}

.swappage .cardstyle .inputsec .inputlabel {
    color: var(--whitetext);
    font-size: 14px;
}

.swappage .cardstyle .inputsec input {
    background: none;
    border: none;
    color: var(--whitetext);
    width: 100%;
}
.swappage .cardstyle .inputsec input::placeholder{
    color: var(--whitetext);
}
.swappage .cardstyle .inputsec input:focus-visible {
   outline: none;
}

.swappage .cardstyle .inputsec input:focus {
    box-shadow: none;
    border: none !important;
}

.swappage .cardstyle .swaparrow {
    background: var(--themecolor);
    border-radius: 50px;
    height: 32px;
    width: 32px;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

}

.swappage .cardstyle .swaparrow svg {
    font-size: 14px;
}

.swappage .cardstyle .arrowrow {
    justify-content: center;
    margin-bottom: 20px;
}

.swappage .connect_btn {
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 500;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 10px;
    border-radius: 11px;
    width: 100%;
}

.swappage .connect_btn:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);

}
.swappage .currencybtn{
    background: var(--cardbg);
    padding: 4px;
    color: var(--whitetext);
    font-size: 14px;
    border: none;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.swappage .currencybtn:hover{
    background: var(--themehover);
}
.swappage .currencybtn .coinimg{
    width: 20px;
    height: 20px;
    object-fit: contain;
}
.swappage .inputsec .rightbtnsec{
    display: flex;
    justify-content: end;
    min-width: 155px;
}
.swappage .inputsec .inputsecrow{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.swappage .inputsec .inputsecrow .rightmaxbtn{
    display: flex;
    align-items: center;
    gap: 10px;
}
.swappage .inputsec .balancerow{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.swappage .valuecardsec {
    padding: 15px;
    /* border: 1px solid var(--lightgrey); */
    border-radius: 13px;
    background: var(--bgcolor);

}

.swappage .valuecardsec.valuecardsecnpng
{
background: transparent;
border-radius: 0px;
padding: 0px;
}

.swappage .valuecardsec .descpara {
    color: var(--themecolor);
    font-size: 14px;
    text-align: center;
}

.swappage .valuecardsec .label {
    color: var(--whitetext);
    font-size: 14px;
    text-align: center;
    margin-bottom: 5px;
}

.swappage .valuecardsec .labels {
    color: var(--themecolor);
    font-size: 14px;
    text-align: center;
}

.swappage .valuecardsec .secrow {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.roundiconbtn
{
    background-color: var(--cardbg);
    border:none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color:var(--themecolor);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}
.roundiconbtn:hover
{
    background-color: var(--themecolor);
    color: var(--cardbg);

}

.tooltip_button
{
    background-color: transparent !important;
    border:none;
    border-radius: 0px;
    color:#fff;
    padding: 0px;
    margin-left: 10px;
}

.tooltip_style
{
    padding: 16px;
    font-size: 16px;
    line-height: 130%;
    border-radius: 11px;
    max-width: 320px;
    z-index: 101;
    background: rgb(35, 40, 51);
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px, rgba(14, 14, 44, 0.1) 0px 4px 12px -8px;
}


.cursorpointer
{
    cursor: pointer;
}
.cursorpointer:hover
{
    color:var(--themecolor) !important;
}
.inputsecrowecipeinet input
{
    background-color: transparent !important;
    border:none;
    color:#fff;
    width: 100% !important;
    outline: 0px !important;
    margin-top: 10px;
}
.swappage .tabbtnsec{
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: center;
}
.swappage .tabbtnsec .tabbtn{
    background: var(--bgcolor);
    color: var(--whitetext);
    font-size: 16px;
    border-radius: 0px;
    outline: 0;
}
.swappage .tabbtnsec .tabbtn:active{
    outline: 0;
    border-color: transparent;
}
.swappage .tabbtnsec .tabbtn.activetab{
background: var(--themecolor);
}
@media only screen and (max-width:575px)
{
    .secrowcolmob
    {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
    .secrowcolmob .text-center
    {
        text-align: left !important;
    }
}