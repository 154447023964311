@font-face {
    font-family: "Satoshi-Light"; 
    src: local("Satoshi-Light"),
      url("../fonts/Satoshi-Light.otf") format("opentype");
  }
  @font-face {
    font-family: "Satoshi-Regular"; 
    src: local("Satoshi-Regular"),
      url("../fonts/Satoshi-Regular.otf") format("opentype");
  }
  @font-face {
    font-family: "Satoshi-Medium"; 
    src: local("Satoshi-Medium"),
      url("../fonts/Satoshi-Medium.otf") format("opentype");
  }
  @font-face {
    font-family: "Satoshi-Black"; 
    src: local("Satoshi-Black"),
      url("../fonts/Satoshi-Black.otf") format("opentype");
  }
  @font-face {
    font-family: "Satoshi-Bold"; 
    src: local("Satoshi-Bold"),
      url("../fonts/Satoshi-Bold.otf") format("opentype");
  }

  /* .containmax{
    font-family: "Satoshi-Regular" !important;  
  } */
.themetxt{
    color: var(--themecolor) !important;
}
.mainhead{
    font-size: 3.5rem;
}
.cardtext{
    font-size: 16px !important;
    text-align: left;
}
.socialsection{
    display: flex;
    margin-top: 3%;
}
.socialsection img{
    width: 33px;
    height: 33px;
    aspect-ratio: 3/2;
    object-fit: contain;
}
.socialsection a{
  margin-left: 8px;
}
.socialsection a:nth-child(1){
    margin-left: 0px;
  }
.socialsection a:hover .mediass {
    display: none;
  }
  .socialsection a:hover .mediasshover{
      display: block;
  }
  .socialsection a .mediasshover{
      display: none;
  }
/* landing page */

.provideborder {
    border: 1px solid #ffffff14;
    border-radius: 10px;
    background: #ffffff0a;
    padding: 20px;
    /* height: 340px; */
    /* min-height: 340px; */
    height: 100%;
}
.welcome{
    color: var(--whitetext);
    font-family: "Satoshi-Bold" !important;
   /* font-family:  Chakra Petch,sans-serif !important; */
    font-weight: 600;
}
.welcome1{
    color: var(--whitetext);
    /* font-family: "Satoshi-Regular";  */
}
.marqueeimg1{
    border: 1px solid var(--themecolor);
    border-radius: 6px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    min-height: 80px;
    object-fit: contain;
}
.marqueeimg{
     /* border: 1px solid var(--themecolor);
     width: 180px;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 16px;
     min-height: 80px;
     object-fit: contain; */

     border: 1px solid var(--themecolor);
     border-radius: 6px;
     width: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 16px 25px;
     min-height: 80px;
     height: 80px;
     object-fit: contain;
     max-width: 90%;
     margin: auto;
}
.colwidth .marqueeimg{
    transition: all .3s ease;
}
.colwidth .marqueeimg:hover{
  transform: translateY(-8px);
  /* filter: drop-shadow(2px 4px 6px #5eabf945); */
}

.blurviolent{      
        background: #5eabf9;
        filter: blur(50px);
        height: 90px;
        opacity: .2;
        position: absolute;
        left: 20%;
        top: 10%;
        transform: rotate(178deg);
        width: 150px;
        z-index: -1;
}
.blurviolent1{      
    background: #5eabf9;
    filter: blur(50px);
    height: 90px;
    opacity: .2;
    position: absolute;
    right: 0%;
    bottom: 0%;
    transform: rotate(178deg);
    width: 120px;
    z-index: -1;
}
.blurviolentlast{
        background: #5eabf9;
        filter: blur(38px);
        height: 80px;
        opacity: .3;
        position: absolute;
        right: 11%;
        top: -25%;
        transform: rotate(178deg);
        width: 140px;
        z-index: -1;
}
.blurviolent2{
    background: #5eabf9;
    filter: blur(38px);
    height: 80px;
    opacity: .2;
    position: absolute;
    left: 0%;
    top: 10%;
    transform: rotate(178deg);
    width: 130px;
    z-index: -1;
}
.blur1{
        top: 0;
        position: absolute;
        max-width: 26%;
}
.blur2{
    bottom: 0;
    right: 0;
    position: absolute;
    max-width: 26%;
}
.heading{
    max-width: 70%;
    margin: auto;
}
.welcomepara{
    color: var(--lightgrey);
    /* font-family: 'Space Grotesk', sans-serif !important; */
    font-family: "Satoshi-Regular" !important; 
    font-size: 17px;
}
.donutback{
    background: #5eabf91A;
    padding: 8px;
    border-radius: 6px;
}
.tradenow{
    background: var(--themecolor);
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    border-radius: 20px;
    padding: 6px 22px;
    margin-top: 10px;
}
.tradenow:hover{
    background: var(--btnhover);
    color: var(--whitetext);
}
.donutexp{
    width:92px;
    height:92px;
    object-fit: contain;
    aspect-ratio: 3/2;
}
.posrel{
    position: relative;
    z-index: 2;
    /* overflow: hidden; */
}
.donutexp1{
    position: absolute;
    right: -12%;
    max-width: 45%;
}
.donutexp2{
    position: absolute;
    right: -22%;
    max-width: 45%;
    top: 6%;
}
.donutrose{
    background: var(--themecolor);
    padding: 10px 26px;
    border-radius: 16px;
}
.donutrose img{
    margin-top: -35%;
}
.donutrose .rows{
    justify-content: space-between;
    align-items:center;
}
.blackbtn{
    background: #1D242F;
    color: var(--whitetext);
    padding: 6px 24px;
    border-radius: 20px;
}
.blackbtn:hover{
    background: var(--btnhover);
    color: var(--whitetext);
}
.maillist{
    display: flex;
}
.maillist input{
    background: #FF9ED6 !important;
    border: #FF9ED6 !important;
    border-radius: 20px;
    color: var(--whitetext);
}
.maillist input::placeholder{
  color: var(--whitetext);
}
.maillist input:focus{
    box-shadow:0 0 0 0.25rem rgb(13 110 253 / 0%);
    color: var(--whitetext);
}
.partnermarquee .partnerwidth:first-child{
    margin-left: 4%;
}
.partnermarquee {
    display: flex;
    flex-direction: row;
    gap: 50px;
}
.partnerwidth{
    /* border: 1px solid var(--themecolor);
    padding: 16px;
    border-radius: 4px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center; */
}
.coming{
    color: var(--whitetext);
}
.welcomess{
    color: var(--whitetext);
    font-size: 24px;
    text-align: left;
    /* font-family: "Satoshi-Medium";  */
}
@media screen and (min-width: 120px) {
    .donutrose{
        padding: 30px 26px;
    }
  
}
@media screen and (max-width: 1199px) {
    .donutrose{
        padding: 30px 26px;
    }
    .containmax{
        max-width: 90%;
    }
}
@media screen and (max-width: 991px) {
    .heading1{
        font-size: 29px;
    }
    .donutrose{
        padding: 30px 26px;
    }
    .marqueeimg{
        min-height: 70px;
        height: 70px;
        max-width: 95%;
    }
}


@media screen and (min-width:768px)
{
    .colwidth
    {
        width: 25% !important;
    }
}


@media screen and (max-width: 767px) {
    .donutrosepadding
    {
        padding-top: 45px !important;
    }
    .donutrose .rows{
        flex-wrap: wrap-reverse;
    }
   .donutexp1,.donutexp2{
    display: none;
   }
   .heading{
    max-width: 100%;
    margin: auto;
}
.welcome{
    font-size: 30px;
}
.partnerwidth{
    width: 170px;
}
/* .donutrose img{
    margin-top: 15%;
} */
.donutrose{
    padding: 20px 20px;
}
.marqueeimg{
    min-height: 65px;
    height: 65px;
    max-width: 90%;
}
  }


  @media screen and (min-width:576px) and (max-width:767px)
  {
    .donutrose img{
        margin-top: -30%;
    }
  }

@media screen and (max-width: 575px) {
  /* .welcome{
    font-size: 23px;
} */
.marqueeimg{
    min-height: 56px;
    height: 56px;
    max-width: 100%;
}
.coming{
   font-size: 14px;
   margin-bottom: 0px;
}
  }

  /* @media screen and (max-width: 340px) {
    .welcome{
      font-size: 21px;
  }
    } */

    @media screen and (min-width: 1200px) {
    .donutrose{
        width: 80%;
        margin: auto;
    }
    /* .welcome{
        font-size: 45px;
    } */
    .donutrose .welcome{
        font-size: 40px;
    }
}
/* landing page end */
@media only screen and (min-width:1200px)
{
    .rowmin
    {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (min-width:1200px) and (max-width:1399px)

{
.donutrosepadding
{
    padding-top: 100px !important;
}
}


@media only screen and (min-width:1400px)

{
.donutrosepadding
{
    padding-top: 150px !important;
}
.donutrose img{
    margin-top: -130px !important;
}
}
@media only screen and (min-width:992px) and (max-width:1199px)
{
    .rowmin
    {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .donutrose{
        width: 90%;
        margin: auto;
    }
}
/* #partner_5 .marqueeimg
{
    padding: 25px 35px !important;
} */


/* .colwidth a
{
    border: 1px solid var(--themecolor);
    border-radius: 6px;
    display: inline-block;
}

.colwidth a img
{
    border: none;
    border-radius: 0px;
} */