.offswapcomp .headlabel{
    color: var(--whitetext);
    font-weight: 600;
    font-size: 16px;
}
.offswapcomp  .styleinput ,.offswapcomp  .styleinput:focus,.offswapcomp  .styleinput:active{
    background: var(--bgcolor);
    color: var(--whitetext);
    border: none;
    box-shadow: none;
}
.offswapcomp  input::placeholder{
    color:var(--whitetext);
}
.offswapcomp .minihead{
    color: var(--desctext);
    font-size: 16px;
}
.offswapcomp .interborder{
    background: var(--themecolor);
    padding: 5px;
    color: #fff;
    border-radius: 5px;
}
.offswapcomp  svg{
    fill: var(--themecolor);
}

 .buybtn {
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 500;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 10px;
    border-radius: 11px;
    width: 100%;
}

 .buybtn:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);

}