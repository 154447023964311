@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;500;600;700&display=swap');

:root {
  --themecolor: #5eabf9;
  --bgcolor: #060606;
  --greytext: #3D4E6B;
  --lighttext: #9D9D9D;
  --whitetext: #ffffff;
  --desctext: #DEDEDE;
  --cardbg: #121217;
  --labelbg: #1c1e22;
  --blacktext: #000000;
  --progressbar: #313846;
  --redtext: #FD2B2B;
  --lightgrey: #859BC0;
  --btnhover:#77d1ff;
  --themehover:#5eabf926;
  --cancel:#da1524;
  --acc_clr:#232832;
  --themegrad: linear-gradient(90deg,#003f82,#0072ec);
  --themegrad1: linear-gradient(90deg,#0072ec,#003f82);

}

@font-face {
  font-family: "Satoshi-Light"; 
  src: local("Satoshi-Light"),
    url("./assests/fonts/Satoshi-Light.otf") format("opentype");
}
@font-face {
  font-family: "Satoshi-Regular"; 
  src: local("Satoshi-Regular"),
    url("./assests/fonts/Satoshi-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Satoshi-Medium"; 
  src: local("Satoshi-Medium"),
    url("./assests/fonts/Satoshi-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Satoshi-Black"; 
  src: local("Satoshi-Black"),
    url("./assests/fonts/Satoshi-Black.otf") format("opentype");
}
@font-face {
  font-family: "Satoshi-Bold"; 
  src: local("Satoshi-Bold"),
    url("./assests/fonts/Satoshi-Bold.otf") format("opentype");
}


body {
  margin: 0;
  /* font-family: 'Space Grotesk', sans-serif !important; */
  font-family: "Satoshi-Regular" !important;  
  background: var(--labelbg) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#tablestyle1 .asrt-table-body {
  max-height: 250px;
}

#launchpadtable .asrt-table-head {
  display: block;
  background: var(--cardbg);
  margin-bottom: -15px;
  padding: 20px;

}

#launchpadtable .asrt-table-head .float-right.text-right {
  width: 100%;
  height: 35px;
}

#launchpadtable .asrt-table-head .float-right.text-right .table_filter {
  width: 100% !important;
}

#launchpadtable .asrt-table-head .float-right.text-right .table_filter input {
  width: 100% !important;
  background: var(--bgcolor);
  border: none;
  height: 35px;
  color: var(--whitetext);
  background-image: url('./assests/images/searchicon.png');
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: top 10px right 10px;
}

#launchpadtable .asrt-table-head .float-right.text-right .table_filter input::placeholder {
  color: var(--whitetext);
}

#launchpadtable .asrt-table-head .float-right.text-right .table_filter input:focus {
  box-shadow: none;
  background-image: none;

}

/* #launchpadtable .asrt-table-body {
  max-height: 650px;

} */

#launchpadtable::-webkit-scrollbar-track,
#tablestyle1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #3E86D0;
}

#launchpadtable::-webkit-scrollbar,
#tablestyle1::-webkit-scrollbar {
  width: 8px;
  background-color: #3E86D0;
  height: 8px;
  border-radius: 20px;
}

#launchpadtable::-webkit-scrollbar-thumb,
#tablestyle1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--themecolor);
}

.as-react-table {
  background: var(--cardbg);
}

#tablestyle1 .asrt-table-foot,
#launchpadtable .asrt-table-foot {
  display: none
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #000;
}
::-webkit-scrollbar {
  width: 8px;
  background-color: #000;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #3E86D0;
}

.tooltips{
  background: var(--cardbg);
}
@media screen and (max-width:767px){
  #ingwidth_5
  {
    padding: 20px 30px !important;
  }  
}

@media screen and (min-width:768px){
  #ingwidth_5
  {
    padding: 25px 35px !important;
  }  
}
.btn_trans_icon_table:disabled
{
  opacity: 0.5 !important;
}

.btn_trans_icon_table
{
  background-color: transparent;
  border:none;
  color:var(--themecolor);
}
.btn_trans_icon_table:hover
{
  color:var(--whitetext);
}
.btn_trans_status_table
{
  background-color: var(--themecolor);
  color:var(--whitetext);
  border:none;
  padding: 8px 10px;
  line-height: 1;
  pointer-events: none;

}

.btn_trans_status_table.disabled_btn_apply
{
  background-color: var(--bgcolor);
  color:var(--whitetext);
  border:none;
  padding: 8px 10px;
  line-height: 1;
  pointer-events: none;
  opacity: 0.5;
  /* cursor: none; */
}
.tabstyle .nav-tabs
{
border:none;
}
.asrt-td-loading
{
  background-color: transparent !important;
}
.apexcharts-menu-icon
{
    display: none;
}
.logo_overlay
{
  opacity: 0.1;
  position: relative;
}
.logo_overlay_none
{
  opacity: 1;
  position: relative;
}
#loader_div
{
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index: 999999;
  padding: 20px;
  border-radius: 50%;
}
body.overflow_loading
{
  overflow:hidden;
}


.circle {
  width: 100px;
  height: 100px;
  background: transparent;
  border-radius: 50%;
  border: 2px dashed var(--themecolor);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
  
} 
.circle_img
{
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index: 999999;
}


@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

.asrt-page-length {display:none !important;}

input[type="search"]::-webkit-search-cancel-button{
 display: none !important;    
}