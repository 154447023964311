.modalstyle .modalheader {
    border-bottom: none;
}

.modalstyle .modalbody {
    border-bottom: none;
}

.modalstyle [class="modal-content"] {
    background: var(--cardbg);
    /* box-shadow: var(--themecolor) -1px 0px 14px; */
    /* border: 1px solid var(--themecolor); */
    min-width: 320px;
    max-width: 420px;
    margin: auto;
    border-radius: 0px;
    width: 100%;
    /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter{
    padding: 20px;
    border-top: 0px;
}
.modalstyle .btnstyle {
    background: var(--themecolor);
    border-radius: 7px;
    color: var(--whitetext);
    font-size: 12px;
    border: 1px solid var(--themecolor);
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
}

.modalstyle .btnstyle:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #fff;
}
.modalstyle .modalheader .modaltitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    color: var(--whitetext);

}
.modalstyle .modalheader{
    display: flex;
    justify-content: space-between;
    padding: 25px;
}
.modalstyle .modalheader .closebtn{
    background: none;
    padding: 0px;
    border: none;
    color: var(--whitetext);
    cursor: pointer;
}
.modalstyle .modalbody {
    /* display: flex;
    gap: 20px;
    justify-content: space-evenly; */
    padding:  25px;

}
.modalstyle .modalbody .label{
    font-size: 12px;
    color: var(--whitetext);
}
.modalstyle .modalbody .walletimg{
    width: 50px;
    height: 50px;
    object-fit: contain;
}
.modalstyle  .walletrow{
    width: 100px;
    height: 100px;
    padding: 10px;
}
.modalstyle  .walletrow:hover .label{
    /* background: var(--themecolor); */
    color: var(--themecolor);

}
.modalstyle  .walletrow:hover {
opacity: 0.6;
cursor: pointer;
}
.modalstyle .modalfooter .footerdesc{
    color: var(--whitetext);
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
}
.modalstyle .modalbody .innerhead{
    color: var(--themecolor);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 24px;
    text-transform: uppercase;
}

.modalstyle .modalbody .innerheadwhite
{
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
}
.innerheadwhitelg
{
    color: var(--whitetext);
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 10px;
    height: 46px;
    line-height: 46px;
}
.innerheadwhitesm
{
    color: var(--whitetext);
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.modalstyle .netbtn{
    background: var(--bgcolor);
    border: none;
    border-radius: 5px;
    color: var(--whitetext);
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 5px 15px;
    width: 100%;
    margin-bottom: 12px;
    font-size: 14px;
    height: 48px;
}
.modalstyle
{
    padding-right: 8px !important;
}

.modalstyle .modalbody ul{
    list-style:none;
    padding-left: 0px;
}
.modalstyle .netbtn .netimg{
  width: 30px;
  height: 30px;
}
@media screen and (max-width:400px) {
    .modalstyle .modalbody .innerheadwhite
{
    font-size: 14px;
}


    .modalstyle [class="modal-content"] {
        min-width: 280px;
        max-width: 280px;
    }
   
}

@media screen and (max-width:575px)
{
    .buttonstyle_ul

{
    padding-left: 9px !important;
    padding-right: 9px !important;
}
}
.tooltip_button
{
    background-color: transparent !important;
    border:none;
    border-radius: 0px;
    color:#fff;
    padding: 0px;
    margin-left: 10px;
}
.tooltip_button_minus
{
    margin-top: -10px !important;
}
.tooltip_style
{
    padding: 16px;
    font-size: 16px;
    line-height: 130%;
    border-radius: 11px;
    max-width: 320px;
    z-index: 101;
    background: rgb(35, 40, 51);
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px, rgba(14, 14, 44, 0.1) 0px 4px 12px -8px;
}
.button_ul
{
    display: flex;
    flex-wrap: wrap;
} 
.button_ul_new
{
    display: flex;
    align-items: center;
    justify-content: space-around;
    border:1px solid #fff;
    padding: 5px;
    border-radius: 11px;
}
.button_ul li
{
    margin-bottom: 10px;
}
.buttonstyle_ul
{
    background-color: #1c2029;
    color:#fff;
    border-radius: 11px;
    padding: 5px 13px;
    font-size: 12px;
    font-weight: 600;
}
.buttonstyle_ul_new
{
    background-color: transparent;
    color:#fff;
    border-radius: 11px;
    padding: 5px 13px;
    font-size: 12px;
    font-weight: 600;
}
.buttonstyle_ul_new:hover,.buttonstyle_ul_new:focus,.buttonstyle_ul_new.active
{
    background: rgba(254, 128, 201, 0.15);
    color:#fff;  
}
.buttonstyle_ul_pink
{
    background-color: var(--themecolor);
}
.buttonstyle_ul:hover,.buttonstyle_ul:focus
{
    background: rgba(254, 128, 201, 0.15);
    color:#fff;
}
.sliipage_input,.sliipage_input:hover,.sliipage_input:focus
{
    background-color: #1c2029;
    color:#fff;    
    border:none;
    border-radius: 11px;
    max-width: 150px;
    margin: 5px 0px;
}
.sliipage_input:focus,.form-control:focus
{
    box-shadow: 0 0 0 0.25rem rgb(72 75 81 / 12%) !important;
}
.percentage_text
{
    display: inline-block;
    color: rgb(60, 76, 101);
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin-left: 5px;
}
.orangetext
{
    font-size:14px;
     color:#F3841E;
      
}
.sliipage_input_sm,.sliipage_input_sm:hover,.sliipage_input_sm:focus
{
    max-width: 60px;
}
.toggleswitch input {
    background: var(--themecolor);
    border: none;

}
.togglesec {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.togglesec .spanlabel {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);

}
.cardCalculator
{
    background-color: #1c2029;
    color: #fff;
    border-radius: 11px;
    padding: 15px 13px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
}
.cardCalculator .calculatorinput
{
background-color: transparent;
border:none;
color:#fff;
text-align: right;
box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25) !important;
font-size: 16px;
padding: 0px;
margin-right: 10px;
max-width: 60px;
}
.cardCalculator .calculatorinput::placeholder
{
    color:#ccc !important;
}

.spancal
{
color:#fff;
font-size: 16px;

}
.spandesc
{
    color: rgb(255, 255, 255);
    font-weight: 400;
    line-height: 1.5;
    font-size: 12px;
    text-align: right;
    margin-top: 10px;
}
.btnicon
{
    background-color: rgb(35, 40, 51);
    box-shadow: none;
    color: rgb(255, 255, 255);
    border-color: rgb(35, 40, 51);
    border:none;
    border-radius: 8px;
    margin-left: 10px;
    padding: 5px;
}
.btniconnew
{
    background-color: rgb(35, 40, 51);
    box-shadow: none;
    color: rgb(255, 255, 255);
    border-color: rgb(35, 40, 51);
    border:none;
    border-radius: 8px;
    margin-left: 10px;
    padding: 6px 10px; 
}
.btnicon:hover
{
    background: rgba(254, 128, 201, 0.15);

    border-color: rgb(254, 128, 201);
}
.btniconnew:hover
{
    background: #564f5342;

    border-color: rgb(254, 128, 201);
}
.checkboxstyle
{
    background-color: #1c2029 !important;
    border-color: #1c2029 !important;
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px !important;
    border-radius: 8px;

}
.checkboxstyle:focus
{
    box-shadow:  0 0 0.25rem rgb(254 128 201 / 42%) !important;
}
.arrowdowntext
{
    color:#fff;
    font-size: 20px;
}
.pinkcardmodal
{
    background-color: #5eabf9;
    color: #fff;
    border-radius: 11px;
    padding: 20px 20px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;

}
.rowmarginminus
{
    margin-left: -35px;
    margin-right: -35px;
}
.accordionhead
{
    background-color: #5eabf9;
    border-color: #5eabf9;


}
.accordionitem
{
    border-radius: 0px !important;
    background-color: #5eabf9;
    border-color: #5eabf9;
    color:#fff;
}
.accordionhead button
{
    color:#fff !important;
    text-align: center;
    font-size: 14px;
    width: max-content;
    font-weight: 700;
    justify-content: center;
    margin: 0 auto;
    background-color: transparent !important;
    box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25) !important;
}
.accordionhead button::after
{
    filter: brightness(0)invert(1);
    margin-left: 10px;
    background-size: 15px;
    margin-top: 3px;
}
.accordionhead button:not(.collapsed)::after
{
margin-top: 0px !important;
}
.gridtext
{
    display: grid;
    grid-template-columns: 2.5fr 3.2fr;
    row-gap: 8px;
    grid-template-rows: repeat(4, auto);
}
.gridtext p{
    font-size: 14px;
    margin-bottom: 5px !important;
}
.gridul
{
    padding-left: 18px !important;
    margin-top: 15px;
}
.gridul li
{
list-style-type: disc;
font-size: 12px;
padding-bottom: 5px !important;
}
.tooltip_style_minus
{
    margin-top: -5px !important;
    padding: 16px;
    font-size: 16px;
    line-height: 130%;
    border-radius: 11px;
    max-width: 320px;
    z-index: 101;
    background: rgb(35, 40, 51);
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px, rgba(14, 14, 44, 0.1) 0px 4px 12px -8px;
}
.wirdbraktxt
{
    word-break: break-all;
    height: unset;
    line-height: 1;
}