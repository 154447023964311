.footersec {
    background: url('../images/footerback.png') no-repeat;
    background-size: 100% 100%;
    width: 100%;
    border-top: 1px solid #dbdbdb14;
    padding: 40px 10px 0px;
}
.footersec .footdesc{
  color: var(--whitetext);
  font-size: 15px;
  font-weight: 500;
}
.footersec  .headmenu{
    font-weight: 700;
    font-size: 18px;
    color: var(--themecolor);
    margin-bottom: 15px;
}
.footersec ul li{
    margin-bottom: 10px;
}
.footersec ul li a{
    color: var(--whitetext);
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;

}
.footersec ul {
list-style-type: none;
padding-left: 0px;
}
.socialsec {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
}
.socialsec a:hover {
    filter: drop-shadow(2px 4px 6px #4084D5);
   
}

@media screen and (max-width:991px) {
    .footersec {
        text-align: center;
    }
    .socialsec {
     justify-content: center;
    }
    
}