.poolcard {
    background: var(--cardbg);
    padding: 20px;
    padding-top: 25px;
    width: 350px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    clip-path: polygon(30px 0, calc(100% - 21px) 0, 100% 16px, 100% calc(100% - 11px), calc(100% - 16px) 100%, 10px 100%, 0 calc(100% - 11px), 0 25px)
}

.poolcard .toprow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
}

.poolcard .toprow .mainlabel {
    color: var(--whitetext);
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px;
}

.poolcard .toprow .sublabel {
    color: var(--whitetext);
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 5px;
}

.poolcard .toprow .logoimg {
    width: 45px;
    height: 45px;
    object-fit: contain;
}

.poolcard .aprrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.poolcard .aprrow .label {
    color: var(--whitetext);
    font-size: 16px;

}

.poolcard .aprrow .value {
    color: var(--whitetext);
    font-size: 16px;

}

.poolcard .harvestrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.poolcard .harvestrow .label {
    color: var(--whitetext);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
}
.poolcard .harvestrow .value {
    color: var(--whitetext);
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
}
.poolcard .harvestrow .balance {
    color: var(--whitetext);
    font-size: 12px;
    font-weight: 400;
}

.poolcard .harvestrow .harvestbtn{
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 40px;
    width: 120px;
}
.poolcard .harvestrow.harvestbtn:hover{
    background: var(--btnhover) ;
    border: 1px solid var(--btnhover) ;
    color: rgb(255, 255, 255) ;
    transition: none 0s ease 0s ;
}
.poolcard .btnsec .label{
  color: var(--whitetext);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
}



.poolcard .btnsec .walletbtn{
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 40px;
    width: 100%;
}

.poolcard .btnsec  .walletbtn:hover {
    background: var(--btnhover) ;
    border: 1px solid var(--btnhover) ;
    color: rgb(255, 255, 255) ;
    transition: none 0s ease 0s ;

}
.poolcard .detailsec .corelabel {
    background: var(--themecolor);
    border: 2px solid var(--whitetext);
    border-radius: 10px;
    color: var(--whitetext);
    padding: 2px 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap:5px;
    margin-bottom: 0px;
    width: 120px;
}
.poolcard .detailsec{
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.poolcard .detailsec .label{
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    cursor: pointer;
}
.poolcard .detailrow{
    margin-top: 15px;
}
.poolcard .detailrow .listrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.poolcard .detailrow .listrow .label{
    color: var(--whitetext);
    font-size: 14px;
    margin-bottom: 0px;
}
.poolcard .detailrow .listrow .value{
    color: var(--whitetext);
    font-size: 14px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.poolcard .detailrow .contractlink{
    color: var(--whitetext);
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    text-align: end;
}
.poolcard .detailrow .contractlink:hover{
    color: var(--themecolor);
    font-size: 14px;
}
.poolcard .detailrow  .linksec{
    display: flex;
    align-items: end;
    flex-direction: column;
}
.poolcard .mansec{
    display: flex;
    gap: 10px;
}

.tooltip_style
{
    padding: 16px;
    font-size: 16px;
    line-height: 130%;
    border-radius: 11px;
    max-width: 320px;
    z-index: 101;
    background: rgb(35, 40, 51);
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px, rgba(14, 14, 44, 0.1) 0px 4px 12px -8px;
}
.tooltip_button
{
    background-color: transparent !important;
    border:none;
    border-radius: 0px;
    color:#fff;
    padding: 0px;
    margin-left: 10px;
}

.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0;
    background-color: var(--whitetext);
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: var(--themecolor);
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
  }
  .ribbonright {
    top: -10px;
    right: -10px;
  }
  .ribbonright::before,
  .ribbonright::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }


  .ribbonright::before {
    top: 0;
    left: 0;
  }
  .ribbonright::after {
    bottom: 0;
    right: 0;
  }
  .ribbonright span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
  }
  
@media screen and (max-width:400px) {
    .poolcard {
        width: 320px;
    }

    
    
}
.manuallabel
{
    width: max-content !important;
}



.poolcard .labelsm {
    color: var(--whitetext);
    font-size: 16px;

}

.btnicon
{
    background-color: #1c2029;
    color:#fff;
    border-radius: 0px;
    padding: 8px 13px;
    font-size: 12px;
    font-weight: 600;
}



.btnicon:hover,.btnicon:focus
{
    background: rgba(254, 128, 201, 0.15);
    color:#fff;
}


.declabel{
    font-size: 14px;
    color: var(--themecolor);
    text-align:center;
    font-weight: 600;
    text-decoration: none;
    background: transparent;
    border: none;
}
.declabel:hover{
    color: var(--whitetext);

}